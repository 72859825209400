<template>
  <div>
    <!-- Filters -->
    <list-filters
      :gender.sync="gender"
      :family-status.sync="familyStatus"
      :city.sync="city"
      :town.sync="town"
      :street.sync="street"
      :neighborhood.sync="neighborhood"
      :weakness.sync="weakness"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :service-filter.sync="serviceFilter"
      :main-service.sync="mainService"
      :gender-options="genderOptions"
      :status-options="statusOptions"
    />
    <b-card no-body>
      <b-card-header class="pb-50" />
      <b-card-body>
        <b-row>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6>بحث حسب الاسم او رقم البطاقة</h6>
              <b-form-input
                v-model="search"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col xl="2">
            <label> العمر</label>
          </b-col>

          <b-col
            cols=""
            md="3"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>من</h6>
              <b-form-input
                v-model="min_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <b-col
            cols=""
            md="3"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>إلى</h6>
              <b-form-input
                v-model="max_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col xl="2">
            <label> تاريخ التسجيل</label>
          </b-col>
          <b-col
            md="3"
            class="mb-md-0 mb-2"
          >
            <h6>من</h6>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
          <b-col
            md="3"
            class="mb-md-0 mb-2"
          >
            <h6>إلى</h6>
            <flat-pickr
              v-model="endDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ totalUsers }}      عدد المستفيدين</label>
          <label />

        </b-col>

      </div>

      <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="fetchAll"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column::items="fetchAll" User primary-key="id" -->

        <!-- Column: Role -->

        <!-- Column: another number
         -->

        <template #cell(other_contact_details)="data">

          <b-button
            v-if="Array.isArray(data.item.other_contact_details)"
            variant="outline-primary"
            @click="showModal(data.item.id)"
          >
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            <span class="align-middle ml-50"> ارقام الهواتف الأخرى </span>
          </b-button>
          <span v-else>{{ data.item.other_contact_details.number }}</span>

          <b-modal
            v-if="Array.isArray(data.item.other_contact_details)"
            :ref="`my-modal-${data.item.id}`"
            centered
            ok-only
            ok-title="حسنا"
            :visible="modalValue === data.item.id"
            cancel-variant="outline-secondary"
            title-tag="div"
          >

            <b-table
              responsive="sm"
              :items="data.item.other_contact_details"
              :fields="fields"
            />

            <!-- </b-card-code> -->

          </b-modal>

        </template>

        <!-- Column: Status -->
        <template #cell(service_status)="data">

          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.service_status)}`"
            class="text-capitalize"
          >
            {{ data.item.service_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span>
            <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{
                name: 'service-committe-report',
                params: { id: data.item.person_id },
              }"
              v-if="data.item.service_status == 'قيد الانتظار'"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تقرير اللجنة</span>
            </b-dropdown-item> -->
      
         
            <b-dropdown-item
              :to="{
                name: 'show-beneficiary-info',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50"> عرض الحالة</span>
            </b-dropdown-item>

           
         

          </b-dropdown>
          </span>
          
        </template>
      </b-table>
      <hr>
      <br>
      <b-row>
        <b-col xl="4" />
        <b-col xl="3">
          <b-button
            variant="purple"
            class="shadow"
            @click="download"
          >
            <vue-excel-xlsx
              ref="childComponent"
              :data="datatest"
              :columns="columns"
              :file-name="new Date().toISOString().slice(0, 10)"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
            </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BModal,
  BFormInvalidFeedback,
  VBModal,
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ListFilters from './ListFilters.vue'
import useList from './useList'
import StoreModule from './StoreModule'
import store from '@/store'


export default {
  setup() {
    const APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) { store.registerModule(APP_STORE_MODULE_NAME, StoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) { store.unregisterModule(APP_STORE_MODULE_NAME) }
    })

    const genderOptions = [
      { label: 'ذكر', value: 1 },
      { label: 'أنثى', value: 2 },
      { label: ' غير محدد ', value: 0 },
    ]

    const statusOptions = [
      { label: 'بالأنتظار', value: 0 },
      { label: 'مفتوحة', value: 1 },
      { label: 'مغلقة', value: 2 },
      { label: 'حالة جديدة من قسم التسجيل ', value: 4 },
      { label: 'بانتظار الموافقة', value: 5 },
      { label: 'تم تحويلها', value: 6 },
    ]
    //  const serviceOptions = [
    //   { label: "Pending", value: 0 },
    //   { label: "Opened", value: 1 },
    //   { label: "Closed", value: 2 },

    // ];
  
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

    const  deactiveuser= (id) => {
      this.$swal({
      title: "سبب تعطيل المستخدم",
      input: "text",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
      inputAttributes: {
        autocapitalize: "off",
      },

      CancelButtonText: "إلغاء",
      confirmButtonText: "حفظ",
      showLoaderOnConfirm: true,
    }).then(res => {
      if(res.value){
      
      // منرسل الملاحظة للبنى
      //بعدين منطلع بوب اب تأكيد الحذف
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          // let url = `/api/v1/donor-sub-account/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            
            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
      }
    })

    
   
  };

    const {
      fetchBenServices,

      tableColumns,
      fetchAll,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      ExcelDone,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      gender,
      familyStatus,
      town,
      street,
      neighborhood,
      weakness,
      city,
      search,
      min_age,
      max_age,
      startDate,
      fileExcel,
      endDate,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      openStatus,
      changeStatus,
      requestStatus,

      succesStory,
      datatest,
    } = useList()

    return {
      // Sidebar
      fetchAll,
      datatest,
      ExcelDone,
      fetchBenServices,
      changeStatus,
      requestStatus,
      openStatus,
      succesStory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      deactiveuser,
      // Filter
      search,
      min_age,
      max_age,
      avatarText,
      serviceOptions: [],
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      genderOptions,

      statusOptions,

      // Extra Filters
      fileExcel,
      startDate,
      endDate,
      town,
      street,
      neighborhood,
      weakness,
      city,
      gender,
      familyStatus,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      max: maxDate,
    }
  },
  components: {
    BModal,
    ListFilters,
    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,

  },
  data() {
    return {
      role: '',
      rolename:'',
      modalValue:null,
      fields: [
        { key: 'relation', label: 'صلة القرابى' },
        { key: 'number', label: 'الرقم' },
        { key: 'notes', label: 'الملاحظات' },
      ],

      items: [],
      columns: [
        {
          label: 'الرقم',
          field: 'id',

        },
        {
          label: 'الاسم',
          field: 'first_name',
        },
        {
          label: 'النسبة',
          field: 'last_name',
        },
        {
          label: 'الجنس',
          field: 'gender.name',
        },
        {
          label: 'رقم الهاتف',
          field: 'other_contact_details
',
        },

        {
          label: 'الخدمة',
          field: 'service',
        },
        {
          label: 'حالة الخدمة',
          field: 'service_status',
        },
        {
          label: 'تاريخ الميلاد',
          field: 'birth_date',
        },

        {
          label: 'تاريخ التسجيل',
          field: 'registration_date',
        },
        {
          label: 'نقاط الضعف',
          field: 'weakness',
        },
        {
          label: ' المنطقة',
          field: 'city',
        },
        {
          label: ' الناحية',
          field: 'town',
        },
        {
          label: 'الحي',
          field: 'neighborhood',
        },
        {
          label: 'تفاصيل الحي',
          field: 'street',
        },
        {
          label: 'طبيعة العائلة',
          field: 'family_status',
        },
      ],
      filename: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const queryParams = JSON.parse(localStorage.getItem('queryParams'))
    // console.log(queryParams)
    this.gender = queryParams.value.gender
    this.familyStatus = queryParams.value.familyStatus
    this.city = queryParams.value.city
    this.town = queryParams.value.town
    this.neighborhood = queryParams.value.neighborhood
    this.street = queryParams.value.street
    this.planFilter = queryParams.value.planFilter
    this.statusFilter = queryParams.value.service_status
    // this.serviceFilter=queryParams.value.service
    this.mainService = queryParams.value.main_service
    this.min_age = queryParams.value.min_age
    this.max_age = queryParams.value.max_age
    this.startDate = queryParams.value.maxDate
    this.endDate = queryParams.value.minDate
    this.role = userData.roles
    this.currentPage = queryParams.value.page
    this.search = queryParams.value.search
     this.rolename = userData.roles[0].name
  },
  methods: {
    showModal(id) {
      // console.log(id)
      this.modalValue = id;
      this.$refs[`my-modal-${id}`].show()
    },
    showNumber() {
      this.$refs.childComponent.show()
    },
    async download() {
      await this.fileExcel().then(() => {
      //  console.log(this.datatest);

        this.$refs.childComponent.exportExcel()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<template>
  <div>
    <!-- Filters -->
    <list-filters
      :gender.sync="gender"
      :family-status.sync="familyStatus"
      :city.sync="city"
      :town.sync="town"
      :street.sync="street"
      :neighborhood.sync="neighborhood"
      :weakness.sync="weakness"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :service-filter.sync="serviceFilter"
      :main-service.sync="mainService"
      :gender-options="genderOptions"
      :status-options="statusOptions"
    />
    <b-card no-body>
      <b-card-header class="pb-50" />
      <b-card-body>
        <b-row>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6>بحث حسب الاسم او رقم البطاقة</h6>
              <b-form-input
                v-model="search"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col xl="2">
            <label> العمر</label>
          </b-col>

          <b-col
            cols=""
            md="3"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>من</h6>
              <b-form-input
                v-model="min_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <b-col
            cols=""
            md="3"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>إلى</h6>
              <b-form-input
                v-model="max_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col xl="2">
            <label> تاريخ التسجيل</label>
          </b-col>
          <b-col
            md="3"
            class="mb-md-0 mb-2"
          >
            <h6>من</h6>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
          <b-col
            md="3"
            class="mb-md-0 mb-2"
          >
            <h6>إلى</h6>
            <flat-pickr
              v-model="endDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ totalUsers }}       عدد المستفيدين</label>
          <label />

        </b-col>

      </div>

      <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="fetchAll"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column::items="fetchAll" User primary-key="id" -->

        <!-- Column: Role -->

        <!-- Column: another number
         -->
         <template #cell(deactive_reason)="data">

<span v-if="data.item.deactive_reason != null">معطل بسبب {{data.item.deactive_reason}}</span>
<span v-else>غير معطل</span>



  

 


</template>


        <template #cell(other_contact_details)="data">

          <b-button
            v-if="Array.isArray(data.item.other_contact_details)"
            variant="outline-primary"
            @click="showModal(data.item.id)"
          >
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            <span class="align-middle ml-50"> ارقام الهواتف الأخرى </span>
          </b-button>
          <span v-else>{{ data.item.other_contact_details.number }}</span>

          <b-modal
            v-if="Array.isArray(data.item.other_contact_details)"
            :ref="`my-modal-${data.item.id}`"
            centered
            ok-only
            ok-title="حسنا"
            :visible="modalValue === data.item.id"
            cancel-variant="outline-secondary"
            title-tag="div"
          >

            <b-table
              responsive="sm"
              :items="data.item.other_contact_details"
              :fields="fields"
            />

            <!-- </b-card-code> -->

          </b-modal>

        </template>

        <!-- Column: Status -->
        <template #cell(service_status)="data">

          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.service_status)}`"
            class="text-capitalize"
          >
            {{ data.item.service_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{
                name: 'service-committe-report',
                params: { id: data.item.person_id },
              }"
              v-if="data.item.service_status == 'قيد الانتظار'"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تقرير اللجنة</span>
            </b-dropdown-item> -->
      
         
            <b-dropdown-item
              :to="{
                name: 'show-beneficiary-info',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض الحالة</span>
            </b-dropdown-item>
         
            <b-dropdown-item-button v-if="rolename == 'data_entry'"
            @click="deactiveuser(data.item.id )"
              >
                  <feather-icon  icon="TrashIcon" class="mr-50" />
                  <span class="align-middle ml-50">تعطيل المستخدم</span>
                </b-dropdown-item-button>

          </b-dropdown>
        </template>
      </b-table>
      <hr>
      <br>
      <b-row>
        <b-col xl="4" />
        <b-col xl="3">
          <b-button
            variant="purple"
            class="shadow"
            @click="download"
          >
            <vue-excel-xlsx
              ref="childComponent"
              :data="datatest"
              :columns="columns"
              :file-name="new Date().toISOString().slice(0, 10)"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
            </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BModal,
  BFormInvalidFeedback,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ListFilters from './ListFilters.vue'
import useList from './useList'
import StoreModule from './StoreModule'
import store from '@/store'


export default {
  setup() {
    const APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) { store.registerModule(APP_STORE_MODULE_NAME, StoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) { store.unregisterModule(APP_STORE_MODULE_NAME) }
    })

    const genderOptions = [
      { label: 'ذكر', value: 1 },
      { label: 'أنثى', value: 2 },
      { label: ' غير محدد ', value: 0 },
    ]

    const statusOptions = [
      { label: 'بالأنتظار', value: 0 },
      { label: 'مفتوحة', value: 1 },
      { label: 'مغلقة', value: 2 },
      { label: 'حالة جديدة من قسم التسجيل ', value: 4 },
      { label: 'بانتظار الموافقة', value: 5 },
      { label: 'تم تحويلها', value: 6 },
    ]
    //  const serviceOptions = [
    //   { label: "Pending", value: 0 },
    //   { label: "Opened", value: 1 },
    //   { label: "Closed", value: 2 },

    // ];
  
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

  //   const deactiveuser = (id) => {
  //     this.$swal({
  //     title: "سبب تعطيل المستخدم",
  //     input: "text",
  //     customClass: {
  //       confirmButton: "btn btn-primary",
  //       cancelButton: "btn btn-outline-danger ml-1",
  //     },
  //     buttonsStyling: false,
  //     inputAttributes: {
  //       autocapitalize: "off",
  //     },

  //     CancelButtonText: "إلغاء",
  //     confirmButtonText: "حفظ",
  //     showLoaderOnConfirm: true,
  //   }).then(res => {
  //     if(res.value){
      
  //     // منرسل الملاحظة للبنى
  //     //بعدين منطلع بوب اب تأكيد الحذف
  //     this.$swal({
  //       icon: "warning",
  //       title: "هل انت متأكد من الحذف",
  //       confirmButtonText: "موافق",
  //       cancelButtonText: "الغاء",
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "لا",
  //       },
  //     }).then((result) => {
  //       if (result.value) {
  //         // let url = `/api/v1/donor-sub-account/${id}`;
  //         this.$http.delete(url).then((res) => {
  //           //console.log(res);
            
  //           this.$swal({
  //       icon: "warning",
  //       title: "تم الحذف  بنجاح",
  //       confirmButtonText: "موافق",
  //       cancelButtonText: "الغاء",
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "لا",
  //       },
  //     })
            
  //         });
  //       }
  //     });
  //     }
  //   })

    
   
  // };

    const {
      fetchBenServices,

      tableColumns,
      fetchAll,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      ExcelDone,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      gender,
      familyStatus,
      town,
      street,
      neighborhood,
      weakness,
      city,
      search,
      min_age,
      max_age,
      startDate,
      fileExcel,
      endDate,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      openStatus,
      changeStatus,
      requestStatus,

      succesStory,
      datatest,
    } = useList()

    return {
      // Sidebar
      fetchAll,
      datatest,
      ExcelDone,
      fetchBenServices,
      changeStatus,
      requestStatus,
      openStatus,
      succesStory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      search,
      min_age,
      max_age,
      avatarText,
      serviceOptions: [],
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      genderOptions,

      statusOptions,

      // Extra Filters
      fileExcel,
      startDate,
      endDate,
      town,
      street,
      neighborhood,
      weakness,
      city,
      gender,
      familyStatus,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      max: maxDate,
    }
  },
  components: {
    BModal,
    ListFilters,
    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,

  },
  data() {
    return {
      role: '',
      rolename:'',
      fields: [
        { key: 'relation', label: 'صلة القرابى' },
        { key: 'number', label: 'الرقم' },
        { key: 'notes', label: 'الملاحظات' },
      ],
     

      items: [],
      columns: [
        {
          label: 'الرقم',
          field: 'id',

        },
        {
          label: 'الاسم',
          field: 'first_name',
        },
        {
          label: 'النسبة',
          field: 'last_name',
        },
        {
          label: 'الجنس',
          field: 'gender.name',
        },
        {
          label: 'رقم الهاتف',
          field: 'other_contact_details.number',
        },

        {
          label: 'الخدمة',
          field: 'service',
        },
        {
          label: 'حالة الخدمة',
          field: 'service_status',
        },
        {
          label: 'تاريخ الميلاد',
          field: 'birth_date',
        },

        {
          label: 'تاريخ التسجيل',
          field: 'registration_date',
        },
        {
          label: 'نقاط الضعف',
          field: 'weakness',
        },
        {
          label: ' المدينة',
          field: 'city',
        },
        {
          label: ' الناحية',
          field: 'town',
        },
        {
          label: 'الحي',
          field: 'neighborhood',
        },
        {
          label: ' تفاصيل الحي' ,
          field: 'street',
        },
        {
          label: 'طبيعة العائلة',
          field: 'family_status',
        },
      ],
      filename: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const queryParams = JSON.parse(localStorage.getItem('queryParams'))
  //  console.log( " after search")
   // console.log( queryParams)
    this.currentPage = queryParams.value.page
    this.gender = queryParams.value.gender
    this.familyStatus = queryParams.value.familyStatus
    this.city = queryParams.value.city
    this.town = queryParams.value.town
    this.neighborhood = queryParams.value.neighborhood
    this.street = queryParams.value.street
    this.planFilter = queryParams.value.planFilter
    this.statusFilter = queryParams.value.service_status
    // this.serviceFilter=queryParams.value.service
    this.mainService = queryParams.value.main_service
    this.min_age = queryParams.value.min_age
    this.max_age = queryParams.value.max_age
    this.startDate = queryParams.value.maxDate
    this.endDate = queryParams.value.minDate
    this.role = userData.roles
    this.rolename = userData.roles[0].name
   //  console.log( "rolename" + this.rolename)
    
    this.search = queryParams.value.search
  },
  methods: {
    deactiveuser (id){
      
  return new Promise((resolve, reject) => {
    this.$swal({
      title: "سبب تعطيل المستخدم",
      input: "text",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
      inputAttributes: {
        autocapitalize: "off",
      },

      CancelButtonText: "إلغاء",
      confirmButtonText: "حفظ",
      showLoaderOnConfirm: true,
    }).then(res => {
      if(res.value){
        // منرسل الملاحظة للبنى
   //   console.log(res.value + "سبب التعطيل")
        let data =[]
            
            data = {
              person_id:id,
              deactive_reason:res.value,};

        this.$http.post("api/v1/person_deactive", data).then(res1 => {
          if(res1){
            this.$swal({
        icon: "warning",
        title: "تم التعطيل  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then(() => this.refetchData())
          }

        })
      
      
      }})
  })
    },

    
   
  


    showModal(id) {
      // console.log(id)
      this.modalValue = id;
      this.$refs[`my-modal-${id}`].show()
    },
    showNumber() {
      this.$refs.childComponent.show()
    },
    async download() {
      await this.fileExcel().then(() => {
      //   console.log(this.datatest);

        this.$refs.childComponent.exportExcel()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
